@tailwind base;
@tailwind components;
@tailwind utilities;

#article-content p {
  @apply pb-5 text-lg text-gray-500;
}
#article-content h4, #article-content h5 , #article-content h6 , #article-content h3 {
    @apply py-2 font-bold text-2xl text-emerald-600;
  }
#article-content ul {
    @apply px-2 py-1;
}
#article-content ul li {
    @apply py-2;
}